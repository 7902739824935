@font-face {
    font-family: 'Denton Test Regular';
    src: url('/src/assets/fonts/montserrat-regular.otf') format('opentype');
}

@font-face {
    font-family: 'Belgant Aesthetic';
    src: url('/src/assets/fonts/belgant-aesthetic.otf') format('opentype');
}

body {
    font-family: 'Belgant Aesthetic', serif;
    background: #f7fafc;
}

.para_text {
    font-family: 'Denton Test Regular', sans-serif;
}