.container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  
  .section {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
  
    .image {
      width: 100%;
      height: 100%;
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
      background-size: cover;
      background-position: center;
  
      &.one {
        background-image: url(https://images.unsplash.com/photo-1473448912268-2022ce9509d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1441&q=50);
      }
      &.two {
        background-image: url(https://images.unsplash.com/photo-1425913397330-cf8af2ff40a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=50);
      }
      &.three {
        background-image: url(https://images.unsplash.com/photo-1551986782-d0169b3f8fa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=50);
      }
      &.four {
        background-image: url(https://images.unsplash.com/photo-1608478497783-158b45d36042?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=50);
      }
      &.five {
        background-image: url(https://images.unsplash.com/photo-1526409049865-5b0b188372f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=50);
      }
    }
  
    &:first-child .image {
      clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
    }
  }
  
  .spacer {
    height: 100vh;
    width: 100%;
    background: coral;
  }
  