@font-face {
  font-family: 'Belgian Aesthetic';
  src: url('assets/fonts/belgant-aesthetic.otf') format('truetype');
}

html,
body {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Belgian Aesthetic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;