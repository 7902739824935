.App {
    text-align: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: url(C:\Users\paliw\Desktop\rohtak-dome\src\assets\images\Karni.svg), auto;
    /* cursor: url(/Users/arpitpaliwal/Desktop/Rohtak-Dome-Code/src/assets/images/Karni.svg), auto ; */
}

a{
    cursor: url(C:\Users\paliw\Desktop\rohtak-dome\src\assets\images\Karni.svg), pointer ;
    /* cursor: url(/Users/arpitpaliwal/Desktop/Rohtak-Dome-Code/src/assets/images/Karni.svg), pointer ; */
}
