/* Import the .otf fonts using @font-face */
@font-face {
  font-family: 'Denton Test Regular';
  src: url('/src/assets/fonts/montserrat-regular.otf') format('opentype');
}

@font-face {
  font-family: 'Belgant Aesthetic';
  src: url('/src/assets/fonts/belgant-aesthetic.otf') format('opentype');
}

/* Apply font to "Rohtak Dome" text */
.dome_text {
  font-family: 'Belgant Aesthetic', serif;
}

/* Apply font to navigation links */
.nav_link {
  font-family: 'Denton Test Regular', sans-serif;
  color: black;
  transition: color 0.2s;
  position: relative;
}

.nav_link::before {
  content: '';
  position: absolute;
  bottom: -0.5px; /* Adjust this value to position the line */
  left: 0;
  width: 0;
  height: 0.5px; /* Line thickness */
  background-color: #903a19; /* Line color */
  transition: width 0.3s;
}

.nav_link:hover::before {
  width: 100%;
}

.nav_link:hover {
  color: #555555;
}

.cards {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-position: centre;
  background-size: cover;

}

  #card-one{
    top:80%;
    background-image: url(C:\Users\paliw\Desktop\rohtak-dome\src\assets\images\Home_Bg1.jpg);
  }

  #card-two{
    top:100%;
    background-image: url(C:\Users\paliw\Desktop\rohtak-dome\src\assets\images\Home_Bg2.jpg);
  }
